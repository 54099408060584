import styled from 'styled-components';

import { Card } from '@domains/clients/ions/Card.styled';

export const CardData = styled(Card.CardData)`
    padding-top: 32px;
`;

export const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;
