import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Button, Switch, TextField } from '@speeki/global-ui-components';

import { DividedFieldRow, FormWrapper } from '@components/ions/Form.styled';

import {
    ForwardEarthCredentialsBaseFragmentDoc,
    UpsertForwardEarthCredentialsDocument,
} from '@graphql/generated/graphql';

import { useFragment, useMutation } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonsWrapper, CardData } from './CarbonLensCard.styled';

interface CarbonLensCardProps {
    enabled: boolean;
    forwardEarthCredentialsUuid?: string;
    onChange: () => void;
}

const FORM_ID = 'carbon-lens-form';

export const CarbonLensCard = ({
    enabled,
    forwardEarthCredentialsUuid,
    onChange,
}: CarbonLensCardProps) => {
    const { clientId } = useParams<{ clientId: string }>();

    const { data: defaultValues } = useFragment({
        fragment: ForwardEarthCredentialsBaseFragmentDoc,
        fragmentName: 'ForwardEarthCredentialsBase',
        from: `ForwardEarthCredentials:${forwardEarthCredentialsUuid}`,
    });

    const [upsertForwardEarthCredentials, { loading: updateLoading }] =
        useMutation(UpsertForwardEarthCredentialsDocument, {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
        });

    const {
        formState: { errors, isDirty, isValid },
        handleSubmit,
        register,
        reset,
    } = useForm<CarbonLensValues>({
        defaultValues: {
            apiKey: defaultValues?.apiKey || '',
            tenantId: defaultValues?.forwardEarthTenantUuid || '',
            url: defaultValues?.instanceUrl || '',
        },
        mode: 'onChange',
        resolver: yupResolver(CarbonLensSchema),
    });

    const onSubmit = handleSubmit((data) => {
        void upsertForwardEarthCredentials({
            onCompleted: (data) => {
                const { apiKey, forwardEarthTenantUuid, instanceUrl } =
                    data.upsertForwardEarthCredentials;

                reset({
                    apiKey: apiKey,
                    tenantId: forwardEarthTenantUuid,
                    url: instanceUrl,
                });
            },
            variables: {
                input: {
                    apiKey: data.apiKey,
                    forwardEarthTenantUuid: data.tenantId,
                    instanceUrl: data.url,
                    tenantUuid: clientId as string,
                },
            },
        });
    });

    return (
        <Card.Wrapper>
            <Card.InnerWrapper>
                <Card.Header>
                    <Card.AccordionWrapper isExpanded={false}>
                        <Switch
                            checked={enabled}
                            onChange={onChange}
                            testId="54FAO9D"
                        />
                        <Card.CardName>Carbon Lens</Card.CardName>
                    </Card.AccordionWrapper>
                </Card.Header>

                <CardData>
                    <FormWrapper id={FORM_ID} onSubmit={onSubmit}>
                        <DividedFieldRow>
                            <TextField
                                {...register('tenantId')}
                                error={!!errors.tenantId}
                                label={'Tenant ID'}
                                message={errors.tenantId?.message}
                                placeholder={'Enter tenant ID'}
                                testId="dgaxOCF"
                            />

                            <TextField
                                {...register('url')}
                                error={!!errors.url}
                                label={'URL address'}
                                message={errors.url?.message}
                                placeholder={'Enter URL address'}
                                testId="W91IJlt"
                            />
                        </DividedFieldRow>
                        <DividedFieldRow>
                            <TextField
                                {...register('apiKey')}
                                error={!!errors.apiKey}
                                label={'API key'}
                                message={errors.apiKey?.message}
                                placeholder={'Enter API key'}
                                testId="XGk7p5L"
                            />
                        </DividedFieldRow>
                    </FormWrapper>
                </CardData>
            </Card.InnerWrapper>

            {isDirty && (
                <Card.Footer padding={'24px 48px'}>
                    <ButtonsWrapper>
                        <Button
                            disabled={updateLoading}
                            onClick={() => reset()}
                            size="small"
                            testId="f4J46Vy"
                            type="button"
                            variant="secondary"
                        >
                            cancel
                        </Button>
                        <Button
                            disabled={!isValid}
                            form={FORM_ID}
                            isLoading={updateLoading}
                            size="small"
                            testId="KC4bb9W"
                            type="submit"
                            variant="primary"
                        >
                            save
                        </Button>
                    </ButtonsWrapper>
                </Card.Footer>
            )}
        </Card.Wrapper>
    );
};

const CarbonLensSchema = yup.object({
    apiKey: yup.string().required('This field cannot be empty'),
    tenantId: yup
        .string()
        .required('This field cannot be empty')
        .uuid('Invalid format. Please enter a valid UUID'),
    url: yup
        .string()
        .required('This field cannot be empty')
        .url(
            'Invalid format. Please enter a valid URL (e.g., https://example.com)',
        ),
});

type CarbonLensValues = yup.InferType<typeof CarbonLensSchema>;
